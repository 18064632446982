import React from 'react'
import { Layout } from '../components/common'

const Sample = () => (
    <Layout>

        <div className="container">
            <h1>Sample Example Page</h1>
            This is a sample page

        </div>
    </Layout>
)

export default Sample
